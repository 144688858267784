import Portal1 from './portal_1.png';
import Portal2 from './portal_2.png';
import Portal3 from './portal_3.png';
import Portal4 from './portal_4.png';
import Portal5 from './portal_5.png';
import Portal6 from './portal_6.png';
import Portal7 from './portal_7.png';
import Portal8 from './portal_8.png';

const Portals = {
  Portal1,
  Portal2,
  Portal3,
  Portal4,
  Portal5,
  Portal6,
  Portal7,
  Portal8,
}

export default Portals;