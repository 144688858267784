import React from 'react';

const Logo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 425.2 215.3"
      {...props}
    >
      <text
        transform="translate(19.433 184.813)"
        fill="#FFF"
        fontFamily="Orator Std"
        fontSize={21.59}
      >
        {"Serrurerie M\xE9tallerie T\xF4lerie"}
      </text>
      <path
        fill="none"
        stroke="#FFF"
        strokeWidth={0.4}
        d="M164.261 163.063h230.833"
      />
      <defs>
        <path id="prefix__a" d="M0 25h414.523v169.125H0z" />
      </defs>
      <clipPath id="prefix__b">
        <use xlinkHref="#prefix__a" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#prefix__b)"
        fill="#FFF"
        d="M72.819 55.012v-.068c0-3.568 1.642-6.336 4.918-8.313 3.282-1.969 7.913-2.957 13.907-2.957 8.362 0 16.704 2.902 25.024 8.699l8.272-16.543a61.255 61.255 0 00-16.187-8.016C102.95 25.934 97.25 25 91.644 25c-12.171 0-21.584 2.768-28.238 8.303-5.68 4.729-8.915 11.141-9.744 19.213l19.315 4.498a14.103 14.103 0 01-.158-2.002M124.081 84.954c-1.949-3.658-4.561-6.529-7.843-8.625-3.277-2.088-6.891-3.693-10.836-4.816-1.79-.506-3.588-.963-5.387-1.398l-1.297-.299a145.55 145.55 0 00-5.222-1.115 162.906 162.906 0 01-10.125-2.316 23.619 23.619 0 01-1.735-.545l-28.099-6.547c.248 5.123 1.237 9.361 3.063 12.578 2.116 3.732 4.91 6.586 8.377 8.563 3.472 1.969 7.274 3.432 11.409 4.381 4.137.955 8.222 1.789 12.265 2.5 3.37.52 6.499 1.16 9.377 1.92 2.874.764 5.189 1.934 6.949 3.533 1.757 1.59 2.641 3.881 2.641 6.879v.07c0 3.945-1.759 6.998-5.278 9.164-3.518 2.158-8.464 3.242-14.833 3.242a49.742 49.742 0 01-16.112-2.672c-5.231-1.785-9.837-4.314-13.833-7.598l-9.052 16.111c4.944 4.049 10.812 7.195 17.61 9.449 6.796 2.262 13.928 3.385 21.387 3.385 12.549 0 22.268-2.744 29.164-8.23 6.892-5.488 10.333-13.229 10.333-23.207v-.143c-.002-5.848-.974-10.604-2.923-14.264"
      />
      <path
        fill="#FFF"
        d="M231.317 26.531l-16.278 57.587 7.41 26.386L239.305 49.7v80.498h17.748V26.531zM166.082 26.531h17.706v103.667h-17.706zM211.566 96.401l-19.747-69.87h-1.398v46.936l12.52 45.178h14.909l-6.268-22.303zM366.019 27.135h-47.851v103.662h19.536V45.954h28.315zM290.011 27.135h21.526v18.818h-21.526zM127.002 116.864h15.248v13.334h-15.248zM262.396 117.463h15.248v13.334h-15.248zM347.719 116.864h15.245v13.334h-15.245z"
      />
      <g>
        <defs>
          <path id="prefix__c" d="M0 25h414.523v169.125H0z" />
        </defs>
        <clipPath id="prefix__d">
          <use xlinkHref="#prefix__c" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#prefix__d)"
          fill="none"
          stroke="#FFF"
          strokeWidth={0.4}
          d="M19.431 193.694h375.662"
        />
      </g>
    </svg>
  )
}

export default Logo;