import GardeCorps1 from './garde_corps_1.png';
import GardeCorps2 from './garde_corps_2.png';
import GardeCorps3 from './garde_corps_3.png';
import GardeCorps4 from './garde_corps_4.png';
import GardeCorps5 from './garde_corps_5.png';
import GardeCorps6 from './garde_corps_6.png';
import GardeCorps7 from './garde_corps_7.png';
import GardeCorps8 from './garde_corps_8.png';
import GardeCorps9 from './garde_corps_9.png';
import GardeCorps11 from './garde_corps_11.png';
import GardeCorps12 from './garde_corps_12.png';

const GC = {
  GardeCorps1,
  GardeCorps2,
  GardeCorps3,
  GardeCorps4,
  GardeCorps5,
  GardeCorps6,
  GardeCorps7,
  GardeCorps8,
  GardeCorps9,
  GardeCorps11,
  GardeCorps12,
}

export default GC;