import Mob1 from './mob_1.png';
import Mob2 from './mob_2.png';
import Mob3 from './mob_3.png';
import Mob4 from './mob_4.png';
import Mob5 from './mob_5.png';
import Mob6 from './mob_6.png';
import Mob7 from './mob_7.png';
import Mob8 from './mob_8.png';
import Mob9 from './mob_9.png';
import Mob10 from './mob_10.png';
import Mob11 from './mob_11.png';

const Furnitures = {
  Mob1,
  Mob2,
  Mob3,
  Mob4,
  Mob5,
  Mob6,
  Mob7,
  Mob8,
  Mob9,
  Mob10,
  Mob11,
}

export default Furnitures;