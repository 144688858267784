import Verr1 from './verr_1.png';
import Verr2 from './verr_2.png';
import Verr3 from './verr_3.png';
import Verr4 from './verr_4.png';
import Verr5 from './verr_5.png';
import Verr7 from './verr_7.png';
import Verr8 from './verr_8.png';

const Verr = {
  Verr1,
  Verr2,
  Verr3,
  Verr4,
  Verr5,
  Verr7,
  Verr8,
}

export default Verr;