import Cloture1 from './cloture_2.png';
import Cloture2 from './cloture_2_1.png';
import Cloture3 from './cloture_3_1.png';
import Cloture4 from './cloture_4_1.png';
import Portail5 from './portail_5.png';
import Portillon1 from './portillon_1.png';

const Fences = {
  Cloture1,
  Cloture2,
  Cloture3,
  Cloture4,
  Portail5,
  Portillon1,
}

export default Fences;