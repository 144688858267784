import Devanture2 from './devantue_2_1.png';
import Devanture3 from './devanture_3_1.png';
import Devanture4 from './devanture_4_1.png';
import Devanture5 from './devanture_5.png';
import Entrance3 from './entrance_3_1.png';
import Entrance4 from './entrance_4_1.png';
import Entrance5 from './entrance_5_1.png';
import Entrance6 from './entrance_6_1.png';
import Entrance7 from './entrance_7_1.png';
import Entrance8 from './entrance_8_1.png';
import Entrance9 from './entrance_9_1.png';
import Entrance10 from './entrance_10_1.png';
import Entrance11 from './entrance_11_1.png';
import Entrance12 from './entrance_12_1.png';
import Entrance13 from './entrance_13_1.png';
import Entrance14 from './entrance_14_1.png';
import Entrance15 from './entrance_15_1.png';

const Entrance = {
  Devanture2,
  Devanture3,
  Devanture4,
  Devanture5,
  Entrance3,
  Entrance4,
  Entrance5,
  Entrance6,
  Entrance7,
  Entrance8,
  Entrance9,
  Entrance10,
  Entrance11,
  Entrance12,
  Entrance13,
  Entrance14,
  Entrance15,
};

export default Entrance;