import Stair1 from './stair_1.png';
import Stair2 from './stair_2.png';
import Stair3 from './stair_3.png';
import Stair4 from './stair_4.png';
import Stair5 from './stair_5.png';
import Stair6 from './stair_6.png';
import Stair7 from './stair_7.png';
import Stair8 from './stair_8.png';
import Stair9 from './stair_9.png';

const Stairs = {
  Stair1,
  Stair2,
  Stair3,
  Stair4,
  Stair5,
  Stair6,
  Stair7,
  Stair8,
  Stair9,
}

export default Stairs;