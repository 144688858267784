import GO1 from './go.png';
import GO4 from './go_4.png';
import GO5 from './go_5.png';

const GO = {
  GO1,
  GO4,
  GO5,
};

export default GO;