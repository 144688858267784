import CarPort1 from './car_port_1.png';
import Marquise from './marquise_1.png';
import Pergol1 from './pergola_2_1.png';
import Pergol2 from './pergola_3_1.png';
import Pergol3 from './pergola_3.png';
import Pergol4 from './pergola_5_1.png';
import Pergol5 from './pergola_6_1.png';
import Veranda from './veranda_2.png';

const VP = {
  CarPort1,
  Marquise,
  Pergol1,
  Pergol2,
  Pergol3,
  Pergol4,
  Pergol5,
  Veranda,
}

export default VP;